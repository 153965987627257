export { } // export{} needed to mark this file as "external module" to export the types https://stackoverflow.com/a/59499895

// Note: Use camelCase on these fields since typescript is case sensitive and ASP.Net Core automatically
// (and silently) converts json properties being returned over http to camelCase.

declare global {
    interface MemberInfo { // Hold info returned from server about member, if logged in
        memberId: number,
        firstName: string,
        lastName: string,
        email: string,
        phoneRaw: string,
        address: string,
        apt: string,
        city: string,
        state: string,
        zip: string,
        country: string,
        gender: string,
        shirtSize: string,
        tooSoonToRenew: boolean,
        lastDayOfMembership: string
    }

    interface ProdInfo {
        prodId: number,
        prodName: string,
        description: string,
        price: number,
        active: boolean,

        membershipTimeframe: string, // "Month" or "Year"
        isNewMembership: boolean, // This product represents a new membership

        productInUse: boolean
    }
}


export class AppSettings {
    public static BTCAPILocation = () => "https://" + location.host + ":5001";
}
